import * as React from 'react'
import { FieldValues, SubmitErrorHandler, SubmitHandler, UseFormReturn } from 'react-hook-form'
import { FormEventData, TrackingEvent } from '@api/tracking'
import { sendGtmEvent } from '@api/tracking'

export type UseFormTrackedWrapperReturn = {
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
}

export const useFormTrackedWrapper = <TFieldValues extends FieldValues>(
  trackingData: FormEventData,
  form: UseFormReturn<TFieldValues>,
  handler: SubmitHandler<TFieldValues>,
  errorHandler?: SubmitErrorHandler<TFieldValues>
): UseFormTrackedWrapperReturn => {
  const onValid: SubmitHandler<TFieldValues> = React.useCallback(
    (data, event) => {
      sendGtmEvent(TrackingEvent.formSubmitSuccess(trackingData))
      return handler(data, event)
    },
    [trackingData, handler]
  )

  const onInvalid: SubmitErrorHandler<TFieldValues> = React.useCallback(
    (errors, event) => {
      sendGtmEvent(TrackingEvent.formSubmitError('validation', trackingData))
      if (errorHandler) {
        errorHandler(errors, event)
      }
      if (appDevMode) {
        // eslint-disable-next-line no-console
        console.error('Form submit error: ', errors, event)
      }
    },
    [trackingData, errorHandler]
  )

  const handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void> = React.useCallback(
    (event) => {
      sendGtmEvent(TrackingEvent.formSubmit(trackingData))
      return form.handleSubmit(onValid, onInvalid)(event)
    },
    [trackingData, form, onValid, onInvalid]
  )

  return { handleSubmit }
}
