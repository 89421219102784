/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  httpUploadFileAndReceiveJson,
  httpReceiveOnlyJson,
  httpSendOnlyJson,
  httpSendAndReceiveJson
} from '@digital-magic/zod-fetch'
import { apiBaseUrlV1, apiBaseUrlV2 } from '@constants/configuration'
import { FileUploadResponseSchema, FormId, VehicleId } from '@api/endpoints'
import { apiBaseUrlV1Cars } from '@api/endpoints/vehicles'
import {
  BookingRequest,
  BookingRequestSchema,
  BuyoutCommissionRequest,
  BuyoutCommissionRequestSchema,
  CallbackRequest,
  CallbackRequestSchema,
  ClientPersonalDataSchema,
  ExchangeRequest,
  ExchangeRequestSchema,
  InstallmentRequest,
  InstallmentRequestSchema,
  LeasingRequest,
  SubscriptionRequest,
  SubscriptionRequestSchema,
  AcceptClientPersonalDataRequest,
  AcceptClientPersonalDataRequestSchema,
  FormVehicleInitialRequest,
  FormVehicleInitialRequestSchema,
  VehicleMntResponseSchema,
  FormVehicleRequest,
  FormVehicleRequestSchema,
  FormPhotos,
  FormPhotosSchema
} from './types'

export const formsUrl = `${apiBaseUrlV1}/forms`
const callbackUrl = `${formsUrl}/callback`
const bookingUrl = `${formsUrl}/booking`
const subscribeUrl = (vehicleId: VehicleId): string => `${apiBaseUrlV1Cars}/${vehicleId}/subscribe`
const clientUrl = `${formsUrl}/client`
const fileUrl = `${formsUrl}/file`
const buyoutUrl = `${formsUrl}/buyout`
const commissionUrl = `${formsUrl}/commission`
const exchangeUrl = `${formsUrl}/trade-in`
const installmentUrl = `${formsUrl}/car-loan`
const leasingUrl = `${formsUrl}/leasing`

export const formV2Url = `${apiBaseUrlV2}/forms`
const initialDataUrl = `${formV2Url}/initial-data`
const formData = (formId: string): string => `${formV2Url}/form-data/${formId}`
const formPhotos = (formId: string): string => `${formV2Url}/photos/${formId}`

export const postCallbackRequest = (data: CallbackRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: callbackUrl,
    requestSchema: CallbackRequestSchema,
    data
  })

export const postNotificationsSubscribe = (vehicleId: VehicleId, data: SubscriptionRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: subscribeUrl(vehicleId),
    requestSchema: SubscriptionRequestSchema,
    data
  })

export const postBookingRequest = (data: BookingRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: bookingUrl,
    requestSchema: BookingRequestSchema,
    data
  })

export const postPersonalData = (data: AcceptClientPersonalDataRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: clientUrl,
    requestSchema: AcceptClientPersonalDataRequestSchema,
    data
  })

export const getPreviouslySubmittedPersonalData = () =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: clientUrl,
    responseSchema: ClientPersonalDataSchema
  })

export const uploadFile = (data: File) =>
  httpUploadFileAndReceiveJson({
    method: 'POST',
    url: fileUrl,
    responseSchema: FileUploadResponseSchema,
    data
  })

export const postBuyoutRequest = (data: BuyoutCommissionRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: buyoutUrl,
    requestSchema: BuyoutCommissionRequestSchema,
    data
  })

export const postCommissionRequest = (data: BuyoutCommissionRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: commissionUrl,
    requestSchema: BuyoutCommissionRequestSchema,
    data
  })

export const postExchangeRequest = (data: ExchangeRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: exchangeUrl,
    requestSchema: ExchangeRequestSchema,
    data
  })

export const postInstallmentRequest = (data: InstallmentRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: installmentUrl,
    requestSchema: InstallmentRequestSchema,
    data
  })

export const postLeasingRequest = (data: LeasingRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: leasingUrl,
    requestSchema: LeasingRequest,
    data
  })

export const postFormInitialData = (data: FormVehicleInitialRequest) =>
  httpSendAndReceiveJson({
    method: 'POST',
    url: initialDataUrl,
    requestSchema: FormVehicleInitialRequestSchema,
    responseSchema: VehicleMntResponseSchema,
    data
  })

export const postFormData = (formId: FormId, data: FormVehicleRequest) =>
  httpSendOnlyJson({
    method: 'POST',
    url: formData(formId),
    requestSchema: FormVehicleRequestSchema,
    data
  })

export const postFormImages = (formId: FormId, data: FormPhotos) =>
  httpSendOnlyJson({
    method: 'POST',
    url: formPhotos(formId),
    requestSchema: FormPhotosSchema,
    data
  })
