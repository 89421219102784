import { EventData, FormEventData } from './types'

export const TrackingEvent = {
  formSubmit: (trackingData: FormEventData): EventData => ({ event: 'form_submit', ...trackingData }),
  formSubmitSuccess: (trackingData: FormEventData): EventData => ({
    event: 'form_submit_success',
    ...trackingData
  }),
  formSubmitError: (error: unknown, trackingData: FormEventData): EventData => ({
    event: 'form_submit_error',
    error,
    ...trackingData
  })
}
