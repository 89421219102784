import { isHttpError } from '@digital-magic/zod-fetch'
import { ApiErrorCode, ApiErrorCustomObjectSchema } from './types'

export const extractApiErrorCode = (e: unknown): ApiErrorCode | undefined => {
  if (isHttpError(e)) {
    const errorBody = ApiErrorCustomObjectSchema.safeParse(e.response.data)
    if (errorBody.success) {
      return errorBody.data.errorCode
    }
  }
  return undefined
}
